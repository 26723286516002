import { render, staticRenderFns } from "./DetailBMHCForDisease.vue?vue&type=template&id=415d0972&scoped=true"
import script from "./DetailBMHCForDisease.vue?vue&type=script&lang=js"
export * from "./DetailBMHCForDisease.vue?vue&type=script&lang=js"
import style0 from "./DetailBMHCForDisease.vue?vue&type=style&index=0&id=415d0972&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415d0972",
  null
  
)

export default component.exports