<template>
  <div
    ref="modalBMHCForDisease"
    class="modal fade"
    id="modalBMHCForDisease"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalGiayRaVienLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span class="uppercase">{{ handleGetTitle(bmhcType) }}</span>
          </div>
          <button
            @click="closeModal"
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Họ và tên:</label>
                <label v-if="person.name" for>{{ person.name }}</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Ngày sinh:</label>
                <label v-if="person.birthday" for>{{
                  moment(person.birthday).format("DD/MM/YYYY")
                }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Giới tính:</label>
              <label v-if="person.gender" for>{{
                person.gender === 1 ? "Nam" : "Nữ"
              }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Dân tộc:</label>
                <label v-if="person.ethnic_name" for>{{
                  person.ethnic_name
                }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Nghề nghiệp:</label>
              <label v-if="person.career" for>{{ person.career }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class="col-md-2 h-10">
                <label class="fs-14 fw-700 w-44">Địa chỉ:</label>
              </div>
              <div class="col-md-9 h-10" style="position: relative">
                <input
                  class="form--input"
                  type="text"
                  style="position: absolute; transform: translateY(-10px)"
                  v-model="form.address"
                />
              </div>
              <!-- <label for>{{ address }}</label> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Số thẻ bảo hiểm y tế:</label>
                <label v-if="person.insurance_number" for>{{
                  person.insurance_number || ""
                }}</label>
              </div>
            </div>
            <!-- <div class="col-md-6 flex h-10">
                <div class>
                  <label class="fs-14 fw-700 w-44">Hạn sử dụng:</label>
                  <label
                    for
                  >{{ person.insurance_expire && moment(person.insurance_expire).format('DD/MM/YYYY') }}</label>
                </div>
            </div>-->
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Ngày vào viện:</label>
                <label v-if="medicalRecordMain.reception_at" for>{{
                  medicalRecordMain.reception_at &&
                  moment(medicalRecordMain.reception_at).format(
                    "HH:mm DD/MM/YYYY"
                  )
                }}</label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Ngày ra viện:</label>
                <label for v-if="medicalRecordMain.discharge_at">{{
                  medicalRecordMain.discharge_at &&
                  moment(medicalRecordMain.discharge_at).format(
                    "HH:mm DD/MM/YYYY"
                  )
                }}</label>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <div class="form-group">
                <label for="txt-name" class="robo-16-500 font-bold">
                  {{ $t("Trưởng khoa") }}
                </label>
                <el-select
                  class="w-full mt-2"
                  v-model="form.dean"
                  placeholder=""
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div
              v-if="Number(bmhcType) === BMHC_OPTIONS.giayRaVien"
              class="col-6"
            >
              <div class="form-group">
                <label for="txt-name" class="robo-16-500 font-bold">
                  {{ $t("Thủ trưởng đơn vị") }}
                </label>
                <el-select
                  class="w-full mt-2"
                  v-model="form.unitHead"
                  placeholder=""
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div
              v-if="Number(bmhcType) === BMHC_OPTIONS.giayChungNhanPhauThuat"
              class="col-6"
            >
              <div class="form-group">
                <label for="txt-name" class="robo-16-500 font-bold">
                  {{ $t("Giám đốc") }}
                </label>
                <el-select
                  class="w-full mt-2"
                  v-model="form.chairman"
                  placeholder=""
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div>
            <TipTapEditor
              :value="form.content"
              @setValue="(t) => (form.content = t)"
            />
          </div>
          <!-- <div class="row">
              <div class="col-md-12 flex h-10">
                <div class>
                  <label class="fs-14 fw-700 w-44">Chẩn đoán:</label>
                  <label for>{{ medicalRecordMain && medicalRecordMain.treat_dept_main_name }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 flex h-10">
                <div class>
                  <label class="fs-14 fw-700 w-44">Bệnh kèm theo:</label>
                  <label for>{{ medicalRecordMain && medicalRecordMain.treat_dept_including_name }}</label>
                </div>
              </div>
          </div>-->
        </div>
        <div class="modal-footer">
          <button @click="onSubmit" type="button" class="btn bg-pri text-white">
            Lưu phiếu
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import moment from 'moment'
import { mapState } from 'vuex'
import TipTapEditor from '@/components/Editors/Tiptap'
import { mixinAdministrativeForm } from '../../../utils/mixinAdministrativeForm'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import { BMHC_OPTIONS } from '../../../utils/constants'

const DEFAULT_FORM = {
  name: '',
  birth_day: '',
  gender: '',
  address: '',
  address_wards_name: '',
  address_district_name: '',
  address_province_name: '',
  content: '',
  dean: null,
  unitHead: null,
  chairman: null
}

export default {
  name: 'ModalGiayRaVien',
  components: { TipTapEditor },
  mixins: [mixinAdministrativeForm, mixinGetPersonInfo],
  props: {
    type: String,
    bmhcData: Object,
    bmhcType: [String, Number]
  },
  data () {
    return {
      form: {
        ...DEFAULT_FORM
      },
      loading: false,
      contentData: '',
      doctors: [],
      BMHC_OPTIONS
    }
  },
  watch: {
    bmhcData () {
      this.handleMapUpdateData()
    },
    contentData () {
      this.form.content = this.bmhcData?.content || this.contentData
    },
    bmhcType () {
      this.getAdministrativeFormsContent()
    },
    person: {
      deep: true,
      handler () {
        this.handleMapUpdateData()
      }
    },
    medicalRecordMain: {
      deep: true,
      handler () {
        this.handleMapUpdateData()
      }
    }
  },
  mounted () {
    this.handleMapUpdateData()
    this.getAdministrativeFormsContent()
    this.getDoctors()
  },
  computed: {
    ...mapState({
      emrData: (state) => state.emrData,
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain || {}
    })
  },
  methods: {
    handleMapUpdateData () {
      if (this.type === 'update' && this.bmhcData) {
        this.form.content = this.bmhcData.content
        this.form.address =
          this.bmhcData.guardian_address || this.person.address
        this.form.unitHead = this.bmhcData?.unit_head || null
        this.form.dean = this.bmhcData?.dean || null
        this.form.chairman = this.bmhcData?.chairman || null
      } else if (this.type === 'create') {
        this.form.content = this.contentData
        this.form.address =
          this.getAddress(this.medicalRecordMain) ||
          this.getAddress(this.person) ||
          ''
        // `${this.medicalRecordMain?.address_detail}, ${this.medicalRecordMain?.address_wards_name}, ${this.medicalRecordMain?.address_district_name}, ${this.medicalRecordMain?.address_province_name}` ||
        // `${this.person?.address}, ${this.person?.address_wards_name}, ${this.person?.address_district_name}, ${this.person?.address_province_name}` ||
        // ''
      }
    },
    formatDate (time) {
      if (time) {
        moment(time).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    onSubmit () {
      if (this.type === 'create') {
        this.createAdministrativeForm()
      } else {
        this.updateAdministrativeForm()
      }
    },
    async updateReExamination () {},

    closeModal () {
      if (this.type === 'create') {
      }
    },
    openModal (show) {
      window.$('#modalBMHCForDisease').modal(show ? 'show' : 'hide')
    },
    async createAdministrativeForm () {
      this.loading = true
      const params = {
        birth_year:
          this.person.birthday &&
          moment(this.person.birthday).format('DD/MM/YYYY'),
        guardian_gender: this.person.gender,
        guardian_address:
          this.form.address ||
          this.medicalRecordMain?.address_detail ||
          this.person.address ||
          '',
        guardian_address_wards_name:
          this.medicalRecordMain?.address_wards_name ||
          this.person.address_wards_name ||
          '',
        guardian_address_district_name:
          this.medicalRecordMain?.address_district_name ||
          this.person.address_district_name ||
          '',
        guardian_address_province_name:
          this.medicalRecordMain?.address_province_name ||
          this.person.address_province_name ||
          '',
        content: this.form.content,
        guardian_name: this.person.name.toUpperCase(),
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id,
        workspace_id: this.$globalClinic.id,
        user_id: this.$user.id,
        type: Number(this.bmhcType),
        dean: this.form.dean,
        unit_head: this.form.unitHead,
        chairman: this.form.chairman
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postCreateAdministrativeForms(params)
        .then((r) => {
          this.$emit('onCreatedSuccess')

          this.form = {
            ...this.defaultForm,
            content: this.contentData,
            address:
              this.getAddress(this.medicalRecordMain) ||
              this.getAddress(this.person) ||
              ''
            // `${this.medicalRecordMain?.address_detail}, ${this.medicalRecordMain?.address_wards_name}, ${this.medicalRecordMain?.address_district_name}, ${this.medicalRecordMain?.address_province_name}` ||
            // `${this.person?.address}, ${this.person?.address_wards_name}, ${this.person?.address_district_name}, ${this.person?.address_province_name}` ||
            // ''
          }

          this.$toast.open({
            message: 'Thêm thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateAdministrativeForm () {
      if (!this.person) return
      this.loading = true
      const id = this.$route.query.vId
      const params = {
        birth_year:
          this.person.birthday &&
          moment(this.person.birthday).format('DD/MM/YYYY'),
        guardian_gender: this.person.gender,
        guardian_address: this.form.address || undefined,
        guardian_address_wards_name:
          this.person.address_wards_name || undefined,
        guardian_address_district_name:
          this.person.address_district_name || undefined,
        guardian_address_province_name:
          this.person.address_province_name || undefined,
        content: this.form.content,
        reception_at: this.medicalRecordMain?.reception_at,
        discharge_at: this.medicalRecordMain?.discharge_at,
        guardian_name: this.person.name.toUpperCase(),
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id,
        workspace_id: this.$globalClinic.id,
        user_id: this.$user.id,
        type: Number(this.bmhcType),
        dean: this.form.dean,
        unit_head: this.form.unitHead,
        chairman: this.form.chairman
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .postUpdateAdministrativeForm(id, params)
        .then((r) => {
          this.$emit('onUpdateSuccess')
          window.$('button#closeModal').trigger('click')
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getAdministrativeFormsContent () {
      if (!this.bmhcType) return

      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getAdministrativeFormsContent(this.bmhcType)
      const responseData = response.data
      this.contentData = responseData.content
    },
    async getDoctors () {
      try {
        const clinic_id = this.$globalClinic?.id
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
    <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: "\02026";
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
</style>
