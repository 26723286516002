<template>
  <div class="text-black my-3">
    <div
      class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3"
    >
      <button
        :disabled="loading"
        class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
        @click="handleSignBMHC()"
      >
        <div class="loading">
          <div v-if="loading" class="spinner-border mr-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 16 16"
        >
          <path
            fill="#03267A"
            d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
          />
        </svg>
        {{ $t("multidisciplinary_board.btn_sign") }}
      </button>
      <button
        class="btn border-none text-black px-3 mx-2 text-sm fw-500"
        @click="onPrintNe()"
        :disabled="loading"
      >
        <div class="loading" v-if="loading">
          <div class="spinner-border mr-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
            fill="#03267A"
          />
        </svg>
        {{ $t("multidisciplinary_board.lbl_print") }}
      </button>
      <button
        class="btn border-none text-black px-3 mx-2 text-sm fw-500"
        @click="ModalUpdate(true)"
        :disabled="loading"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
            fill="#03267A"
          />
        </svg>
        {{ $t("multidisciplinary_board.lbl_modify_short") }}
      </button>
      <div
        class="text-black mx-2 text-sm fw-500 h-4 cursor-pointer mb-3"
        @click="backList"
        v-if="!loading"
      >
        <svg
          width="40"
          height="46"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.6276 24.7096L33.6976 17.6376C33.8887 17.4531 34.041 17.2324 34.1458 16.9884C34.2507 16.7444 34.3058 16.4819 34.3081 16.2164C34.3104 15.9508 34.2598 15.6874 34.1593 15.4416C34.0587 15.1959 33.9102 14.9725 33.7224 14.7848C33.5346 14.597 33.3113 14.4485 33.0655 14.3479C32.8198 14.2473 32.5564 14.1967 32.2908 14.1991C32.0253 14.2014 31.7628 14.2565 31.5188 14.3613C31.2748 14.4662 31.0541 14.6185 30.8696 14.8096L23.7976 21.8796L16.7276 14.8096C16.5431 14.6185 16.3225 14.4662 16.0784 14.3613C15.8344 14.2565 15.572 14.2014 15.3064 14.1991C15.0409 14.1967 14.7775 14.2473 14.5317 14.3479C14.2859 14.4485 14.0626 14.597 13.8748 14.7848C13.6871 14.9725 13.5386 15.1959 13.438 15.4416C13.3374 15.6874 13.2868 15.9508 13.2891 16.2164C13.2914 16.4819 13.3466 16.7444 13.4514 16.9884C13.5563 17.2324 13.7086 17.4531 13.8996 17.6376L20.9696 24.7076L13.8996 31.7796C13.7086 31.964 13.5563 32.1847 13.4514 32.4287C13.3466 32.6728 13.2914 32.9352 13.2891 33.2007C13.2868 33.4663 13.3374 33.7297 13.438 33.9755C13.5386 34.2213 13.6871 34.4446 13.8748 34.6323C14.0626 34.8201 14.2859 34.9686 14.5317 35.0692C14.7775 35.1698 15.0409 35.2204 15.3064 35.2181C15.572 35.2157 15.8344 35.1606 16.0784 35.0558C16.3225 34.9509 16.5431 34.7986 16.7276 34.6076L23.7976 27.5376L30.8696 34.6076C31.0541 34.7986 31.2748 34.9509 31.5188 35.0558C31.7628 35.1606 32.0253 35.2157 32.2908 35.2181C32.5564 35.2204 32.8198 35.1698 33.0655 35.0692C33.3113 34.9686 33.5346 34.8201 33.7224 34.6323C33.9102 34.4446 34.0587 34.2213 34.1593 33.9755C34.2598 33.7297 34.3104 33.4663 34.3081 33.2007C34.3058 32.9352 34.2507 32.6728 34.1458 32.4287C34.041 32.1847 33.8887 31.964 33.6976 31.7796L26.6276 24.7076V24.7096Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <!-- // -->
    <div :ref="`documentPrint-${bmhcType}`" v-if="viewForm">
      <div id="GiayRaVienDetail" class="mx-auto hodo-print cs-w">
        <div class="px-8 mt-12">
          <div class="block">
            <div class="col-3">
              <div class="flex">
                <div class>
                  <div>
                    <div class="mb-0 uppercase font-bold fs-17">
                      {{
                        $globalClinic && $globalClinic.organization
                          ? $globalClinic.organization.authority_in_charge
                          : ""
                      }}
                    </div>
                  </div>
                  <div class>
                    <div class="mb-0 uppercase font-bold fs-17">
                      {{
                        $globalClinic && $globalClinic.organization
                          ? $globalClinic.organization.name
                          : ""
                      }}
                    </div>
                  </div>
                  <p
                    class="mb-0 fs-13 uppercase font-bold"
                    style="width: 250px"
                    v-if="
                      $globalClinic.name &&
                      Number(bmhcType) === BMHC_OPTIONS.giayRaVien
                    "
                  >
                    {{ $globalClinic.name || "" }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 font-bold mb-6 ml-2">
              <div class="flex justify-center">
                <div class="fs-18">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
              </div>
              <div class="flex justify-center">
                <div class="fs-18">Độc lập - Tự do - Hạnh phúc</div>
              </div>
              <div class="flex justify-center">
                <label class="fs-18">---------------</label>
              </div>
            </div>
            <div
              v-if="Number(bmhcType) === BMHC_OPTIONS.giayRaVien"
              class="col-2 d-flex justify-content-end text-right ml-2"
            >
              <div class="whitespace-nowrap inline-block text-left">
                <div class="fs-17">MS: 01/BV-01</div>
                <div class="fs-17">
                  Số lưu trữ: {{ person.ref_id || "..................." }}
                </div>
                <div class="fs-17">
                  Mã Y tế: {{ person.person_ref_id || "..................." }}
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <div
              class="font-bold flex flex-col justify-center items-center uppercase fs-24"
            >
              {{ handleGetTitle(bmhcType) }}
            </div>
          </div>
          <div class="block mt-3 mb-2">
            <div class="w-full inline-block">
              <div>
                <label class="mr-1 fs-18">Họ tên người bệnh:</label>
                <span v-if="person.name" class="uppercase font-bold fs-18">{{
                  person.name
                }}</span>
                <label
                  v-else
                  class="content-none content-none-cs fs-18"
                ></label>
              </div>
            </div>
          </div>
          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <div class="col-6">
                  <div class="w-full inline-block">
                    <label class="fs-18">Ngày / tháng / năm sinh:</label>
                    <label v-if="person.birthday" class="mb-0 ml-1 fs-18">{{
                      person.birthday &&
                      moment(person.birthday).format("DD/MM/YYYY")
                    }}</label>
                    <label v-else class="fs-18">....../....../........</label>
                  </div>
                </div>
                <div class="col-3">
                  <div class="w-full inline-block">
                    <label class="fs-18">Tuổi:</label>
                    <label v-if="person.birthday" class="mb-0 ml-1 fs-18">{{
                      person.age
                    }}</label>
                    <label v-else class="fs-18">.......</label>
                  </div>
                </div>
                <div class="col-3">
                  <label class="fs-18">Nam/Nữ:</label>
                  <label v-if="person.gender" class="fs-18 ml-1">{{
                    person.gender === 1 ? " Nam" : " Nữ"
                  }}</label>
                  <label v-else class="fs-18">...............</label>
                </div>
              </div>
            </div>
          </div>
          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <div class="col-6">
                  <label class="fs-18">Dân tộc:</label>
                  <label v-if="person.ethnic_name" class="mb-0 ml-1 fs-18">{{
                    person.ethnic_name
                  }}</label>
                  <label v-else class="fs-18"
                    >...............................................</label
                  >
                </div>
                <div class="col-6">
                  <label class="fs-18">Nghề nghiệp:</label>
                  <label v-if="person.career" class="mb-0 ml-1 fs-18">{{
                    person.career
                  }}</label>
                  <label v-else class="fs-18"
                    >...............................................</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <label class="mb-0 fs-18">Mã số BHXH:</label>
                <!-- <label
                  v-if="person.insurance_number"
                  class="mb-0 ml-1 fs-18"
                >{{ person.insurance_number || '' }}</label>-->
                <label class="fs-18"
                  >...............................................</label
                >
              </div>
            </div>
          </div>
          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <div class="flex">
                  <div class="mb-0 font fs-18">Mã thẻ BHYT:</div>
                </div>
              </div>
            </div>
          </div>

          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <div class="flex fs-18">
                  <BoxNumber
                    style="width: 60% !important"
                    bhyt="true"
                    :str="person && person.insurance_number"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <div class="flex">
                  <div class="fs-18">Địa chỉ:</div>
                  <div class="mb-0 ml-1 fs-18">{{ address }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="block mb-2">
              <div class="w-full inline-block">
                <div>
                  <div class="flex">
                    <div class="font fs-18">Hạn sử dụng:</div>
                    <div class="mb-0 ml-1 font fs-18">Từ</div>
                    <div
                      v-if="person.insurance_start_date"
                      class="mb-0 ml-1 font fs-18"
                    >{{ formatDate(person.insurance_start_date) }}</div>
                    <div v-else class="mb-0 ml-1ml fs-18">....../....../......</div>
                    <div class="mb-0 ml-1 font fs-18">Đến</div>
                    <div
                      v-if="person.insurance_expire"
                      class="mb-0 ml-1 font fs-18"
                    >{{ formatDate(person.insurance_expire) }}</div>
                    <div v-else class="mb-0 ml-1 fs-18">....../....../......</div>
                  </div>
                </div>
              </div>
          </div>-->

          <div class="block mb-2">
            <div class="w-full inline-block">
              <div>
                <div class="flex">
                  <div class="fs-18">Vào viện lúc:</div>
                  <div
                    v-if="medicalRecordMain && medicalRecordMain.reception_at"
                    class="mb-0 ml-1 fs-18"
                  >
                    {{ formatDateTime(medicalRecordMain.reception_at) }}
                  </div>
                  <div v-else class="mb-0 ml-1 fs-18">
                    ....../....../..........
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="block mb-2">
            <div class="w-full inline-block">
              <div class="flex">
                <div class="fs-18">Ra viện lúc:</div>
                <div
                  v-if="medicalRecordMain && medicalRecordMain.discharge_at"
                  class="mb-0 ml-1 fs-18"
                >
                  {{ formatDateTime(medicalRecordMain.discharge_at) }}
                </div>
                <div v-else class="mb-0 ml-1 fs-18">
                  ....../....../..........
                </div>
              </div>
            </div>
          </div>

          <div
            class="block mb-2 fs-18 content-size"
            v-html="viewForm.content"
          ></div>

          <div v-if="bmhcType == 15" class="block mt-4">
            <div class="col-6 text-center">
              <div
                v-if="medicalRecordMain && medicalRecordMain.discharge_at"
                class="fs-18"
              >
                {{ getHMDMYVN(medicalRecordMain.discharge_at) }}
              </div>
              <div v-else class="fs-18">
                ....., Ngày ...... tháng ...... năm ......
              </div>
              <div class="font-bold fs-18 uppercase">Thủ trưởng đơn vị</div>
              <div class="italic fs-18">(Ký tên, đóng dấu)</div>
              <div class="fs-18 font-bold mt-20 fs-18">
                {{
                  viewForm.unit_head_info && viewForm.unit_head_info.User
                    ? viewForm.unit_head_info.User.name
                    : ""
                }}
              </div>
            </div>
            <div class="col-6 text-center">
              <div
                v-if="medicalRecordMain && medicalRecordMain.discharge_at"
                class="fs-18"
              >
                {{ getHMDMYVN(medicalRecordMain.discharge_at) }}
              </div>
              <div v-else class="fs-18">
                ....., Ngày ...... tháng ...... năm ......
              </div>
              <div class="font-bold fs-18 uppercase">Trưởng khoa</div>
              <div class="italic fs-18 invisible">(Ký tên, đóng dấu)</div>
              <div class="fs-18 font-bold mt-20 fs-18">
                {{
                  viewForm.dean_info && viewForm.dean_info.User
                    ? viewForm.dean_info.User.name
                    : ""
                }}
              </div>
            </div>
          </div>
          <div v-if="bmhcType == 16" class="block mt-4">
            <div class="row">
              <div class="col-6 text-center"></div>
              <div class="col-6 text-center">
                <div
                  v-if="medicalRecordMain && medicalRecordMain.discharge_at"
                  class="fs-18"
                >
                  {{ getHMDMYVN(medicalRecordMain.discharge_at) }}
                </div>
                <div v-else class="fs-18">
                  ....., Ngày ...... tháng ...... năm ......
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-center">
                <div class="font-bold fs-18 uppercase">Trưởng khoa</div>
                <div class="fs-18 font-bold mt-20 fs-18">
                  {{
                    viewForm.dean_info && viewForm.dean_info.User
                      ? viewForm.dean_info.User.name
                      : ""
                  }}
                </div>
              </div>
              <div class="col-6 text-center">
                <div class="font-bold fs-18 uppercase">Giám đốc</div>
                <div class="fs-18 font-bold mt-20 fs-18">
                  {{
                    viewForm.chairman_info && viewForm.chairman_info.User
                      ? viewForm.chairman_info.User.name
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <ModalBMHCForDisease
      :bmhcType="bmhcType"
      :type="'update'"
      :bmhcData="viewForm"
      @onUpdateSuccess="getBMHCDetail"
    />
    <ModalSignPDF
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_BMHC_ModalSignPDF`"
      :containerIDProps="`${pdfViewerID}_BMHC_ContainerSignPDFID`"
      :elementIDProps="`${pdfViewerID}_BMHC_SignPDFID`"
      :imageIDProps="`${pdfViewerID}_BMHC_SignPDFImageID`"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      v-if="pdfViewerID"
      :ref="`${pdfViewerID}_BMHC_PdfViewer`"
      :containerIDProps="`${pdfViewerID}_ContainerBMHC_PDFViewerID`"
      :elementIDProps="`${pdfViewerID}_BMHC_PDFViewerID`"
    />
  </div>
</template>
    
    <script>
import ModalBMHCForDisease from './ModalBMHCForDisease.vue'
// import _ from 'lodash'
import appUtils from '../../../utils/appUtils'
import { mixinPrint } from '../../../utils/mixinPrint'
import { mixinAdministrativeForm } from '../../../utils/mixinAdministrativeForm'

import BoxNumber from '../../BoxNumber.vue'
import { mapState } from 'vuex'
import {
  BMHC_OPTIONS,
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE
  // SIGN_ENVELOPE_STATUS
} from '../../../utils/constants'
import SignatureRequest from '../../../api/request/SignatureRequest'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import uploadS3File from '../../../utils/uploadS3File'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'

export default {
  name: 'DetailBMHCForDisease',
  components: { ModalBMHCForDisease, BoxNumber, ModalSignPDF, PdfViewer },
  mixins: [mixinPrint, mixinAdministrativeForm, mixinSignPdf],
  data () {
    return {
      viewForm: null,
      loading: false,
      type: null,
      index_media: null,
      list_medias: null,
      images: [],
      bmhcType: null,
      BMHC_OPTIONS,
      pdfViewerID: null,
      signUser: null
    }
  },
  mounted () {
    this.type = this.$route.query.type
    this.bmhcType = this.$route.query.bmhc

    this.getBMHCDetail()
  },
  watch: {
    '$route.query.type' (val) {
      this.type = val
    },
    '$route.query.bmhc' () {
      this.bmhcType = this.$route.query.bmhc

      this.getBMHCDetail()
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain,
      emrData: (state) => state.emrData || {}
    }),
    address () {
      const content_none = '..................'
      const guardian_address =
        this.viewForm.guardian_address ||
        this.person.address ||
        `${content_none}`
      return guardian_address
    },
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    }
  },
  methods: {
    async onPrintNe (isOpen) {
      const self = this
      const bodyHTML = self.$refs[`documentPrint-${this.bmhcType}`].innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = await this.savePdfToS3(base64Data, isOpen)
      return url
    },
    async savePdfToS3 (base64, isOpen = true) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: `BMHC-${this.bmhcType}`,
          path: 'consultations',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data && isOpen) {
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }

        this.loading = false

        return res.data?.documentLink
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    ModalUpdate (show) {
      window.$('#modalBMHCForDisease').modal(show ? 'show' : 'hide')
    },
    backList () {
      this.signUser = null
      this.$router.replace({
        query: { tab: this.$route.query.tab, bmhc: this.$route.query.bmhc }
      })
    },
    // format
    getGender () {
      return appUtils.getGender(this.person.gender)
    },
    formatTime (date) {
      return window.moment(date).format('HH:mm')
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateTime (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === 'vi') {
        // let hm = dt.clone().format('HH:mm')
        const dddd = string.clone().format('dddd')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')

        const arr = dddd.split(' ')
        // loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        // Join all the elements of the array back into a string
        // using a blankspace as a separator
        const ddddCapitalized = arr.join(' ')
        return ddddCapitalized + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    async getBMHCDetail () {
      const self = this
      let id = self.$route.query.vId
      self.loading = true
      await self.$rf
        .getRequest('DoctorRequest')
        .getAdministrativeFormDetail(id)
        .then((r) => {
          self.viewForm = r.data?.data || {}
        })
        .finally(() => {
          self.loading = false
        })
    },
    handleSignBMHC () {
      this.openModalSignPDF()
    },
    async openModalSignPDF () {
      let error
      let signer

      const documentLink = await this.onPrintNe(false)

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }
      const documentType =
        Number(this.bmhcType) === BMHC_OPTIONS.giayRaVien
          ? ENVELOPE_DOCUMENT_TYPE.HOSPITAL_CHECKOUT_FORM
          : Number(this.bmhcType) === BMHC_OPTIONS.giayChungNhanPhauThuat
            ? ENVELOPE_DOCUMENT_TYPE.SURGICAL_CERTIFICATE_FORM
            : null

      const envelopeInfo = await this.handleCheckSignEnvelope(
        this.viewForm,
        documentType
      )

      if (envelopeInfo) return

      const procedure = await this.handleProcessProcedureData(
        (err, signerData) => {
          if (err) {
            error = true

            this.$toast.open({
              message: err,
              type: 'error'
            })
          } else {
            signer = signerData
          }
        },
        documentType
      )

      if (error) return

      const hasPermissionSignatory = procedure?.signatories?.some(
        (item) => item?.user_id === this.$user?.id
      )

      if (!hasPermissionSignatory) {
        this.$toast.open({
          message: 'Bạn không có quyền ký tài liệu này',
          type: 'error'
        })
        return
      }

      this.pdfViewerID = this.viewForm?.id
      const mdtRef = `${this.pdfViewerID}_BMHC_ModalSignPDF`

      this.$nextTick(() => {
        this.$refs[mdtRef].handleOpen(
          documentLink,
          documentType,
          Number(this.viewForm.id),
          signer?.id,
          procedure?.signatories || [],
          null,
          procedure?.id
        )
      })
    },
    async getSignProcedures (docType) {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: docType,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data?.data?.[0]
      } catch (error) {
        console.log(error)
      }
    },
    getUserByRole (role, callback) {
      let user
      let signer
      switch (role) {
        case 'dean':
          const dean = this.viewForm?.dean_info?.User
          if (!dean) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng khoa')
          } else {
            user = dean
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              // eslint-disable-next-line standard/no-callback-literal
              callback(null, signer)
            }
          }

          break

        case 'unit_commander':
          const uintHead = this.viewForm?.unit_head_info?.User

          if (uintHead) {
            signer = uintHead
            user = signer
            // eslint-disable-next-line standard/no-callback-literal
            callback(null, signer)
          } else {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Thủ trưởng đơn vị')
          }

          break
        default:
          break
      }

      return user
    },
    getUserSurgicalCertificate (role, callback) {
      let user
      let signer
      switch (role) {
        case 'dean':
          const dean = this.viewForm?.dean_info?.User
          if (!dean) {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Trưởng khoa')
          } else {
            user = dean
            if (user?.id === this.$user?.id && user?.id) {
              signer = user
              // eslint-disable-next-line standard/no-callback-literal
              callback(null, signer)
            }
          }

          break

        case 'chairman':
          const chairman = this.viewForm?.chairman_info?.User

          if (chairman) {
            signer = chairman
            user = signer
            // eslint-disable-next-line standard/no-callback-literal
            callback(null, signer)
          } else {
            // eslint-disable-next-line standard/no-callback-literal
            callback('Không tìm thấy thông tin Giám đốc')
          }

          break
        default:
          break
      }

      return user
    },

    async handleProcessProcedureData (callback, docType) {
      const procedure = await this.getSignProcedures(docType)

      const signatories = procedure?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          const user =
            docType === ENVELOPE_DOCUMENT_TYPE.HOSPITAL_CHECKOUT_FORM
              ? this.getUserByRole(item?.user_role, callback)
              : this.getUserSurgicalCertificate(item?.user_role, callback)

          return {
            ...item,
            user,
            user_id: user?.id,
            sign_node_id: node?.id
          }
        })

        return arr.concat(signs)
      }, [])

      return { ...procedure, signatories }
    },
    async handleCheckSignEnvelope (bmhcData, docType) {
      try {
        const signEnvelope = await this.handleGetEnvelopeByDocumentID(
          bmhcData,
          docType
        )

        if (!signEnvelope?.id) return

        const signatories = signEnvelope?.envelope_signatories?.map((item) => ({
          ...item,
          user: item?.user_info,
          user_id: item?.user_id,
          sign_node_id: item?.node_signatory_info?.sign_node_id
        }))

        const signerInfo = signEnvelope?.envelope_signatories?.find(
          (item) => item?.user_id === this.$user?.id
        )

        const s3Url = await uploadS3File.getLinkVideoAWS(
          signEnvelope?.document_url
        )

        this.pdfViewerID = bmhcData?.id

        if (signerInfo?.status === 2) {
          const mdtRef = `${this.pdfViewerID}_BMHC_PdfViewer`

          this.$nextTick(() => {
            this.$refs[mdtRef].handleOpen(
              s3Url,
              docType,
              Number(bmhcData.id),
              false,
              false
            )
          })
        } else {
          const hasPermissionSignatory = signatories?.some(
            (item) => item?.user_id === this.$user?.id
          )

          if (!hasPermissionSignatory) {
            this.$toast.open({
              message: 'Bạn không có quyền ký tài liệu này',
              type: 'error'
            })
            return
          }

          const mdtRef = `${this.pdfViewerID}_BMHC_ModalSignPDF`

          this.$nextTick(() => {
            this.$refs[mdtRef].handleOpen(
              s3Url,
              docType,
              Number(bmhcData.id),
              signerInfo?.user_id,
              signatories || [],
              signEnvelope
            )
          })
        }

        return signEnvelope
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess () {
      this.signUser = null
      this.backList()
      // try {
      //   if (!envelopeData?.id) return
      //   const request = new SignatureRequest()
      //   const response = await request.getSignEnvelopeByID(envelopeData?.id)
      //   const envelopeStatusCompleted =
      //     response?.data?.status === SIGN_ENVELOPE_STATUS.completed
      //   if (envelopeStatusCompleted) {
      //     //
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
    },
    async handleGetEnvelopeByDocumentID (docID, docType) {
      try {
        const request = new SignatureRequest()
        const response = await request.getSignEnvelopeByDocumentID({
          doc_id: docID,
          doc_type: docType
        })

        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
    <style lang="scss" scoped>
::v-deep {
  .content-none {
    width: 100%;
    border-bottom: 2px dotted;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .styleText {
    word-wrap: break-word;
  }

  body,
  html {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }
  .font {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }
  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
  p {
    margin-top: 0;
  }
  .text-italic {
    font-style: italic;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .float-none {
    float: none;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block !important;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .align-top {
    vertical-align: top;
  }
  .text-center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .w-full {
    width: 100% !important;
  }
  .w-half {
    width: 50% !important;
  }
  .w-one-third {
    width: 33.333% !important;
  }
  .w-two-thirds {
    width: 66.667% !important;
  }
  .font-bold {
    font-weight: 700;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-sm {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-base {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
  .pl-12 {
    padding-left: 3rem;
  }
  .pl-16 {
    padding-left: 4rem;
  }
  .pr-12 {
    padding-right: 3rem;
  }
  .pr-16 {
    padding-right: 4rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .ml-6 {
    margin-left: 1.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-24 {
    margin-top: 6rem;
  }
  .ml-1 {
    margin-left: 0.25rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .hodo-table figure {
    margin: 0 0 1rem !important;
  }
  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }
  .hodo-table table thead th {
    text-transform: uppercase !important;
  }
  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }

  .cs-w {
    width: 100% !important;
  }
}
</style>
    
    