<template>
  <div
    ref="modalCreateBMHC"
    class="modal fade"
    id="modalCreateBMHC"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCreateBMHCLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span class="uppercase">{{ handleGetTitle($route.query.bmhc) }}</span>
          </div>
          <button
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Họ và tên người thân
                <span class="text-red-500">*</span>
              </div>
              <input class="form--input uppercase" type="text" v-model="form.guardian_name" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Sinh năm
                <span class="text-red-500">*</span>
              </div>
              <input class="form--input" type="number" v-model="form.guardian_birth_year" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Số điện thoại
                <span class="text-red-500">*</span>
              </div>
              <input class="form--input" type="number" v-model="form.guardian_phone" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Giới tính
                <span class="text-red-500">*</span>
              </div>
              <div class="mt-2">
                <el-radio-group v-model="form.guardian_gender">
                  <el-radio :label="1">Nam</el-radio>
                  <el-radio :label="2">Nữ</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Số CMND/CCCD
                <span class="text-red-500">*</span>
              </div>
              <input class="form--input" type="text" v-model="form.guardian_identification" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Ngày cấp</div>
              <input class="form--input" type="date" v-model="form.guardian_identification_date" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Nơi cấp</div>
              <input class="form--input" type="text" v-model="form.guardian_identification_place" />
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Địa chỉ</div>
              <input class="form--input" type="text" v-model="form.guardian_address" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Xã</div>
              <input class="form--input" type="text" v-model="form.guardian_address_wards_name" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Huyện</div>
              <input class="form--input" type="text" v-model="form.guardian_address_district_name" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Tỉnh/Thành phố</div>
              <input class="form--input" type="text" v-model="form.guardian_address_province_name" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="text-black fs-14 fw-700 required">Nội dung</div>
              <TiptapEditor :value="form.content" @setValue="value => form.content = value" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Ngày làm đơn</div>
              <input class="form--input" type="date" v-model="form.date" />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <!-- <button
            :disabled="isDisabledGuardian"
            v-loading="loading"
            @click="handleSubmitGuardian"
            type="button"
            class="btn bg-pri text-white"
          >Lưu thông tin người thân</button>-->
          <button
            :disabled="isDisabled"
            v-loading="loading"
            @click="onSubmit"
            type="button"
            class="btn bg-pri text-white"
          >Lưu phiếu</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { mixinAdministrativeForm } from '@/utils/mixinAdministrativeForm'
import TiptapEditor from '@/components/Editors/Tiptap'

const DEFAULT_FORM = {
  guardian_name: '',
  guardian_birth_year: '',
  guardian_gender: '',
  guardian_identification: '',
  guardian_identification_date: '',
  guardian_identification_place: '',
  guardian_address: '',
  guardian_address_wards_name: '',
  guardian_address_district_name: '',
  guardian_address_province_name: '',
  guardian_phone: '',
  content: '',
  date: window.moment().format('YYYY-MM-DD')
}

export default {
  name: 'ModalCreateBMHC',
  components: { TiptapEditor },
  props: ['type', 'bmhcData', 'bmhcType'],
  mixins: [mixinAdministrativeForm],

  data () {
    return {
      form: {
        ...DEFAULT_FORM
      },
      defaultForm: { ...DEFAULT_FORM },
      loading: false,
      contentData: ''
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases()
      },
      deep: true,
      immediate: true
    },
    bmhcData () {
      this.handleMapUpdateData()
      this.handleGetGuardian()
    },
    contentData () {
      this.form.content = this.bmhcData?.content || this.contentData
    },
    bmhcType () {
      this.getAdministrativeFormsContent()
      this.handleGetGuardian()
    }
  },
  mounted () {
    this.handleMapUpdateData()
    this.getAdministrativeFormsContent()
    this.handleGetGuardian()

    if (this.type === 'create') {
      this.form.content = this.contentData
    }
  },
  computed: {
    ...mapState({
      emrData: state => state.emrData,
      person: state => state.person,
      medicalRecordMain: state => state.medicalRecordMain || {}
    }),
    isDisabled () {
      if (
        !this.form.guardian_name ||
        !this.form.guardian_birth_year ||
        !this.form.guardian_phone ||
        !this.form.guardian_gender ||
        !this.form.guardian_identification ||
        !this.form.content ||
        this.form.content === '<p></p>'
      ) {
        return true
      }
      return false
    }
  },

  methods: {
    handleMapUpdateData () {
      if (this.type === 'update' && this.bmhcData) {
        this.form = {
          ...this.bmhcData,
          date: window
            .moment(this.bmhcData?.date || undefined)
            .format('YYYY-MM-DD'),
          guardian_identification_date: window
            .moment(this.bmhcData?.guardian_identification_date)
            .format('YYYY-MM-DD')
        }
      }
    },
    handleFillGuardian () {
      const guardianData = this.person?.guardian || {}
      if (this.type === 'create' && guardianData) {
        this.form.guardian_address = guardianData.guardian_address
        this.form.guardian_address_district_name =
          guardianData.guardian_address_district_name
        this.form.guardian_address_province_name =
          guardianData.guardian_address_province_name
        this.form.guardian_address_wards_name =
          guardianData.guardian_address_wards_name
        this.form.guardian_birth_year = guardianData.guardian_birth_year
        this.form.guardian_gender = guardianData.guardian_gender
        this.form.guardian_identification =
          guardianData.guardian_identification
        this.form.guardian_identification_date = window
          .moment(guardianData?.guardian_identification_date)
          .format('YYYY-MM-DD')
        this.form.guardian_identification_place =
          guardianData.guardian_identification_place
        this.form.guardian_name = guardianData.guardian_name
        this.form.guardian_phone = guardianData.guardian_phone
        this.form.content = this.contentData
      }
    },
    formatDate (time) {
      if (time) {
        moment(time).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    onSubmit () {
      if (this.isDisabled) {
        this.$toast.open({
          message: 'Vui lòng điền đầy đủ thông tin!',
          type: 'warning'
        })
        return
      }

      if (this.type === 'create') {
        this.createAdministrativeForm()
      } else {
        this.updateAdministrativeForm()
      }
    },
    async createAdministrativeForm () {
      this.loading = true
      const params = {
        ...this.form,
        guardian_name: this.form.guardian_name.toUpperCase(),
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id,
        workspace_id: this.$globalClinic.id,
        user_id: this.$user.id,
        type: Number(this.bmhcType)
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postCreateAdministrativeForms(params)
        .then(r => {
          this.$emit('onCreatedSuccess')
          if (this.person?.guardian?.id) {
            this.handleFillGuardian()
          } else {
            this.form = { ...this.defaultForm, content: this.contentData }
          }
          this.$toast.open({
            message: 'Thêm thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateAdministrativeForm () {
      this.loading = true
      const id = this.$route.query.vId
      const params = {
        ...this.form,
        guardian_name: this.form.guardian_name.toUpperCase(),
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id,
        workspace_id: this.$globalClinic.id,
        user_id: this.$user.id,
        type: Number(this.bmhcType)
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .postUpdateAdministrativeForm(id, params)
        .then(r => {
          this.$emit('onUpdateSuccess')
          window.$('button#closeModal').trigger('click')
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    openModal (show) {
      window.$('#modalCreateBMHC').modal(show ? 'show' : 'hide')
      // let self = this
      // self.$nextTick(function () {
      //   this.form = { ...this.defaultForm, content: this.contentData }
      // })
    },

    async getAdministrativeFormsContent () {
      if (!this.bmhcType) return

      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getAdministrativeFormsContent(this.bmhcType)
      console.log({ response })
      const responseData = response.data
      this.contentData = responseData.content
    },
    async handleSubmitGuardian () {
      if (this.person?.guardian?.id) {
        this.handleUpdateGuardian()
      } else {
        this.handleCreateGuardian()
      }
    },
    async handleCreateGuardian () {
      this.loading = true
      const params = {
        ...this.form,
        guardian_name: this.form.guardian_name.toUpperCase(),
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id
      }
      delete params.content
      delete params.date

      await this.$rf
        .getRequest('DoctorRequest')
        .postCreateGuardian(params)
        .then(r => {
          this.handlePushStoreGuardian(r.data?.data)
          this.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handleUpdateGuardian () {
      if (!this.person?.guardian?.id) return

      this.loading = true
      const id = this.person.guardian.id
      const params = {
        ...this.form,
        guardian_name: this.form.guardian_name.toUpperCase(),
        medical_record_id: Number(this.$route.params.id),
        person_id: this.person.id
      }
      delete params.content
      delete params.date

      await this.$rf
        .getRequest('DoctorRequest')
        .postUpdateGuardian(id, params)
        .then(r => {
          this.handlePushStoreGuardian(r.data?.data)
          this.$toast.open({
            message: 'Lưu thành công',
            type: 'success'
          })

          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePushStoreGuardian (data) {
      this.$store.commit('setPerson', {
        person: { ...this.person, guardian: { ...data } }
      })

      this.handleFillGuardian()
    },
    async handleGetGuardian () {
      if (!this.$route.params?.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getGuardian(this.$route.params?.id)
        const responseData = response.data?.data || {}
        this.handlePushStoreGuardian(responseData)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
    <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: "\02026";
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
</style>
    